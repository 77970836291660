.ruleBox {
    position: absolute;
    top : 20vh;
    height: 60vh;
    width: 300px;
    background-color: gray;
    border: 2px solid white;
    border-radius: 30px;
    overflow-y: scroll;
}
