@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

/*
  ================================
  ================================

              Home

  ================================
  ================================
*/

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.homeContent {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: black;
  opacity: 0.8;
  color: white;
}
.BigTitle {
  align-items: center;
  justify-content: center;
  font-size: 40px;
  position: absolute;
  top : 12vh;
  background-color: black;
  width: 100vw;
  opacity: 0.8;
}
.countdown{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
  ================================
  ================================

              History

  ================================
  ================================
*/

.historyPage {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  flex-direction: column;
  overflow-y: hidden;
  display: flex;
  font-size: 3rem;
  min-height: 150vh;
}

/*
  ================================
  ================================

              Product

  ================================
  ================================
*/
.products {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

/*
  ================================
  ================================

              App.js

  ================================
  ================================
*/

.default {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.App {
  background-image: url(./image/homebackground.jpg);
  background-size: 100%;
}

.defaultWrapper {
  width: 100vw;
  background-color: black;
  opacity: 0.8;
  color: white;
}

.App {
  text-align: center;
}


.App-header {
  /* background-color: #282c34; */
  background-color: wheat;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  opacity: 1;
}

.App-link {
  color: #61dafb;
}

.button {
  justify-content: center;
  align-items: center;
}

.buttonConnect {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  background-color: transparent;
  border: 3px solid white;
  width: 100px;
  height: 40px;
  border-radius: 20px;
}

.controlPanel {
  width: 100vw;
  background-color: black;
  opacity: 0.8;
  color: white;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.panelTable {
  border: 1px solid white;
  width: 80vw;
  min-height: 100px;
}

input {
  border-radius: 20px;
  border: 1px solid white;
}

.btnPrimary {
  background: transparent;
  color: white;
  border: 2px solid white;
  width: 120px;
  height: 40px;
  border-radius: 20px;
  margin-left: 20px;
}

.btnNewRound {
  background: transparent;
  color: white;
  border: 2px solid white;
  width: 120px;
  height: 40px;
  border-radius: 20px;
}

.panelRow {
  margin-bottom: 20px;
}

/*
  rulePage
*/

.rulePage {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.panelRowTitle {
  font-size: 20px;
}

.resultInControl {
  max-width: 100vw;
  font-size: 10px;
}

.panelCardRow1,
.panelCardRow2 {
  display: flex;
  flex-direction: row;
  width : 100vw;
  min-height : 40vh
}

.panelCard {
  margin: 30px;
  padding: 10px;
  flex: 1;
  border: 2px solid white;
}

@media screen and (max-width:500px) {
  .panelCardRow1,
  .panelCardRow2 {
    flex-direction: column;
  }
}