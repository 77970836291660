.countdown-timer {
    width: 40vw;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: black;
    border-radius: 5px;
    padding: 20px;
}

.time-display {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    color: white;
}

@media screen and (max-width:600px) and (min-width:401px) {
    .countdown-timer {
        width: 60vw;
    }
}

@media screen and (max-width:400px) {
    .countdown-timer {
        width: 80vw;
    }
}