.historyContent {
    position: absolute;
    top: 20vh;
    width: 90vw;
}

.containerRow {
    border: 3px solid transparent;
    margin-bottom: 10px;
    align-items: center;
}

.HistorySameRow0 {
    flex: 1;
}

.HistorySameRow1 {
    flex: 3;
}

.HistorySameRow2 {
    flex: 10;
}

.HistorySameRow3 {
    flex: 5;
}

.historyBox {
    font-size: 20px;
    padding: 16px;
}

.historyCardBox {
    display: flex;
    margin-bottom: 5vh;
    height: 80px;
    width : 300px;
    flex-direction: row;
    border: 2px solid white;
    border-radius: 20px;
    font-size: 18px;
    background-color: #34384D;
    color: white;
}

.historyCardRowLeft {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.historyCardRow {
    flex: 8;
    display: flex;
    flex-direction: column;
}

.historyCardUpperRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid white;
}

.historyCardLowerRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.historyCardRowRight {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

#historyLinktoAccount {
    color : white;
    text-decoration: none;
    cursor: pointer;
}